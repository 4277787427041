<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
      <div class="edit-header">
        <span>供地项目</span>
        <div>
          <el-button type="primary" v-show="showMapBtn" @click="toMap"
          >一张图</el-button
        >
          <el-button type="primary" v-show="savebuttonshow" @click="onSubmit"
          >保存</el-button
        >
        </div>
      </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
          ref="baseform"
          :label-position="labelPosition"
          :model="form"
          label-width="130px"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="备案号文号:">
                <el-input v-model="form.BAH_WH"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="合同编号:">
                <el-input v-model="form.BH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="电子备案编号:">
                <el-input v-model="form.DZ_BA_BH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="土地估价报告备案号:">
                <el-input v-model="form.GJBG_BAH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否有估价备案号:">
                <el-input v-model="form.HAVE_BAH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="项目名称:">
                <el-input v-model="form.XM_MC"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="投资主体性质:">
                <el-input v-model="form.TZZT_XZ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="行业分类:">
                <el-input v-model="form.HY_FL"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="土地用途:">
                <el-input v-model="form.TD_YT"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="土地级别:">
                <el-input v-model="form.TD_JB"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="宗地编号:">
                <el-input v-model="form.ZD_BH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="行政区划:">
                <el-select
                  filterable
                  class="input-width"
                  v-model="form.XZQ_DM"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options5"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="土地坐落:">
                <el-input v-model="form.TD_ZL"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供应方式:">
                <el-input v-model="form.GY_FS"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出让年限:">
                <el-input v-model="form.CR_NX"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供地总面积:">
                <el-input-number
                  v-model="form.GD_ZMJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供应面积:">
                <el-input-number
                  v-model="form.GY_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="新增面积:">
                <el-input-number
                  v-model="form.XZ_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批次面积:">
                <el-input-number
                  v-model="form.PC_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批次出让面积:">
                <el-input-number
                  v-model="form.PC_CR_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="存量面积:">
                <el-input-number
                  v-model="form.CL_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="存量出让面积:">
                <el-input-number
                  v-model="form.CL_CR_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="存量（现状出让）:">
                <el-input-number
                  v-model="form.CL_XZCR_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="存量（改变现状出让）:">
                <el-input-number
                  v-model="form.CL_GBXZCR_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="存量其他面积:">
                <el-input-number
                  v-model="form.CL_QT_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="存量（续期）:">
                <el-input v-model="form.CL_XQ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="国有未利用地面积:">
                <el-input-number
                  v-model="form.GY_WLD_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="国有未利用地出让面积:">
                <el-input-number
                  v-model="form.GY_WLD_CRMJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="增减挂钩供地面积:">
                <el-input-number
                  v-model="form.ZJGG_GDMJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="增减挂钩面积:">
                <el-input-number
                  v-model="form.ZJGG_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="交地时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.JD_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="金额:">
                <el-input-number
                  v-model="form.JE"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="固定资产投资额:">
                <el-input-number
                  v-model="form.GDZC_TZE"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开发投资额:">
                <el-input-number
                  v-model="form.KFTZ_ZE"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="投资强度:">
                <el-input-number
                  v-model="form.TZ_QD"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="主体建筑物性质:">
                <el-input v-model="form.ZT_JZ_XZ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="附属建筑物性质:">
                <el-input v-model="form.FS_JZ_XZ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建筑面积:">
                <el-input-number
                  v-model="form.JZ_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地下建筑面积:">
                <el-input-number
                  v-model="form.DX_JZ_MJ"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最小建筑密度:">
                <el-input-number
                  v-model="form.MIN_JZ_MD"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最大建筑密度:">
                <el-input-number
                  v-model="form.MAX_JZ_MD"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最小容积率:">
                <el-input-number
                  v-model="form.MIN_RJL"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最大容积率:">
                <el-input-number
                  v-model="form.MAX_RJL"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最小绿化率:">
                <el-input-number
                  v-model="form.MIN_LHL"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最大绿化率:">
                <el-input-number
                  v-model="form.MAX_LHL"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最小建筑高度:">
                <el-input-number
                  v-model="form.MIN_JZ_GD"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最大建筑高度:">
                <el-input-number
                  v-model="form.MAX_JZ_GD"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否进行开发建设:">
                <el-input v-model="form.KFLY_BZ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="动工时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.DG_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="竣工时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.JG_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开发建设期限:">
                <el-input v-model="form.KFJS_QX"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="签订日期:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.QD_RQ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批准单位:">
                <el-input v-model="form.PZ_DW"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批准文号:">
                <el-input v-model="form.PZ_WH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批准日期:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.PZ_RQ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批准机关:">
                <el-input v-model="form.PZ_JG"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="变更交地时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.BG_JD_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="变更动工时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.BG_DG_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="变更竣工时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.BG_JG_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实际交地时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.SJ_JD_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实际动工时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.SJ_DG_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实际竣工时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.SJ_JG_SJ"
                  placeholder="请选择日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保障性住房用地配建比例:">
                <el-input-number
                  v-model="form.BZX_ZF_BL"
                  :min="0"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保障性住房类型:">
                <el-input v-model="form.BZXZF_LX"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否属于保障性住房:">
                <el-input v-model="form.SF_BZXZF"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="受让人:">
                <el-input v-model="form.SRR2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="项目状态:">
                <el-input v-model="form.XM_ZT"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注:">
                <el-input
                  v-model="form.BZ"
                  type="textarea"
                  :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-dialog
      v-model="dialogFileVisible"
      title="一张图"
      :close-on-click-modal="false"
    >
      <embed
        style="width: 100%; height: 65vh"
        frameborder="0"
        :src="mapUrl + '#toolbar=0'"
      />
    </el-dialog>
  </div>
</template>


<script>
import { ElMessage } from "element-plus";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
export default {
  name:'jsxmgd',
  components: { FloatTitBtn },
  data() {
    return {
      labelPosition: "left",
      form: {
        GD_GUID: '',
        BJID: '',
        XZQ_DM: '',
        BH: '',
        DZ_BA_BH: '',
        XM_MC: '',
        TZZT_XZ: '',
        HY_FL: '',
        TD_YT: '',
        TD_JB: '',
        ZD_BH: '',
        TD_ZL: '',
        GY_FS: '',
        CR_NX: '',
        GD_ZMJ: 0,
        GY_MJ: 0,
        XZ_MJ: 0,
        PC_MJ: 0,
        PC_CR_MJ: 0,
        CL_MJ: 0,
        CL_CR_MJ: 0,
        CL_XZCR_MJ: 0,
        CL_GBXZCR_MJ: 0,
        CL_QT_MJ: 0,
        CL_XQ: '',
        GY_WLD_MJ: 0,
        GY_WLD_CRMJ: 0,
        ZJGG_GDMJ: 0,
        ZJGG_MJ: 0,
        WLYDJX_MJ: 0,
        GKFQDJX_MJ: 0,
        JD_SJ: '',
        JE: 0,
        GDZC_TZE: 0,
        KFTZ_ZE: 0,
        TZ_QD: 0,
        ZT_JZ_XZ: '',
        FS_JZ_XZ: '',
        JZ_MJ: 0,
        DX_JZ_MJ: 0,
        MIN_JZ_MD: 0,
        MAX_JZ_MD: 0,
        MIN_RJL: 0,
        MAX_RJL: 0,
        MIN_LHL: 0,
        MAX_LHL: 0,
        MIN_JZ_GD: 0,
        MAX_JZ_GD: 0,
        KFLY_BZ: '',
        DG_SJ: '',
        JG_SJ: '',
        KFJS_QX: '',
        QD_RQ: '',
        PZ_DW: '',
        PZ_WH: '',
        PZ_RQ: '',
        PZ_JG: '',
        BG_JD_SJ: '',
        BG_DG_SJ: '',
        BG_JG_SJ: '',
        SJ_JD_SJ: '',
        SJ_DG_SJ: '',
        SJ_JG_SJ: '',
        BZX_ZF_BL: 0,
        BZXZF_LX: '',
        SF_BZXZF: '',
        SFWY_CYJJQ: '',
        GJBG_BAH: '',
        HAVE_BAH: '',
        BAH_WH: '',
        OLD_GD_GUID: '',
        BC_XY_QD_RQ: '',
        SRR2: '',
        SF_SH: '',
        XZ_ZT: '',
        XM_ZT: '',
        BZ: '',
        DA_BH: '',
        MODIFY_DATE: '',
        CREATE_USER: '',
        CREATE_DATE: '',
        LMJ: '',
        BAH: '',
        PGF: '',
        PGDW: '',
        XF_ZT: '',
        GXBZ: '',
        LY: '',
        CHECK_ZT: '',
        SFYT: '',
        QSJDJ: '',
        QSJZJ: '',
        CRDJ: '',
        CRZJ: '',
        LX: '',
      },
      forms: {
        GD_GUID: '主键',
        BJID: '办件ID',
        XZQ_DM: '行政区代码',
        BH: '合同编号',
        DZ_BA_BH: '电子备案编号',
        XM_MC: '项目名称',
        TZZT_XZ: '投资主体性质',
        HY_FL: '行业分类',
        TD_YT: '土地用途',
        TD_JB: '土地级别',
        ZD_BH: '宗地编号',
        TD_ZL: '土地坐落',
        GY_FS: '供应方式',
        CR_NX: '出让年限',
        GD_ZMJ: '供地总面积',
        GY_MJ: '供应面积',
        XZ_MJ: '新增面积',
        PC_MJ: '批次面积',
        PC_CR_MJ: '批次出让面积',
        CL_MJ: '存量面积',
        CL_CR_MJ: '存量出让面积',
        CL_XZCR_MJ: '存量（现状出让）',
        CL_GBXZCR_MJ: '存量（改变现状出让）',
        CL_QT_MJ: '存量其他面积',
        CL_XQ: '存量（续期）',
        GY_WLD_MJ: '国有未利用地面积',
        GY_WLD_CRMJ: '国有未利用地出让面积',
        ZJGG_GDMJ: '增减挂钩供地面积',
        ZJGG_MJ: '增减挂钩面积',
        WLYDJX_MJ: 'WLYDJX_MJ',
        GKFQDJX_MJ: 'GKFQDJX_MJ',
        JD_SJ: '交地时间',
        JE: '金额',
        GDZC_TZE: '固定资产投资额',
        KFTZ_ZE: '开发投资额',
        TZ_QD: '投资强度',
        ZT_JZ_XZ: '主体建筑物性质',
        FS_JZ_XZ: '附属建筑物性质',
        JZ_MJ: '建筑面积',
        DX_JZ_MJ: '地下建筑面积',
        MIN_JZ_MD: '最小建筑密度',
        MAX_JZ_MD: '最大建筑密度',
        MIN_RJL: '最小容积率',
        MAX_RJL: '最大容积率',
        MIN_LHL: '最小绿化率',
        MAX_LHL: '最大绿化率',
        MIN_JZ_GD: '最小建筑高度',
        MAX_JZ_GD: '最大建筑高度',
        KFLY_BZ: '是否进行开发建设',
        DG_SJ: '动工时间',
        JG_SJ: '竣工时间',
        KFJS_QX: '开发建设期限',
        QD_RQ: '签订日期',
        PZ_DW: '批准单位',
        PZ_WH: '批准文号',
        PZ_RQ: '批准日期',
        PZ_JG: '批准机关',
        BG_JD_SJ: '变更交地时间',
        BG_DG_SJ: '变更动工时间',
        BG_JG_SJ: '变更竣工时间',
        SJ_JD_SJ: '实际交地时间',
        SJ_DG_SJ: '实际动工时间',
        SJ_JG_SJ: '实际竣工时间',
        BZX_ZF_BL: '保障性住房用地配建比例',
        BZXZF_LX: '保障性住房类型',
        SF_BZXZF: '是否属于保障性住房',
        SFWY_CYJJQ: 'SFWY_CYJJQ',
        GJBG_BAH: '土地估价报告备案号',
        HAVE_BAH: '是否有估价备案号',
        BAH_WH: '备案号文号',
        OLD_GD_GUID: 'OLD_GD_GUID',
        BC_XY_QD_RQ: 'BC_XY_QD_RQ',
        SRR2: '受让人',
        SF_SH: 'SF_SH',
        XZ_ZT: 'XZ_ZT',
        XM_ZT: '项目状态',
        BZ: 'BZ',
        DA_BH: 'DA_BH',
        MODIFY_DATE: '修改日期',
        CREATE_USER: '创建人',
        CREATE_DATE: '创建时间',
        LMJ: 'LMJ',
        BAH: 'BAH',
        PGF: 'PGF',
        PGDW: 'PGDW',
        XF_ZT: 'XF_ZT',
        GXBZ: 'GXBZ',
        LY: 'LY',
        CHECK_ZT: 'CHECK_ZT',
        SFYT: 'SFYT',
        QSJDJ: 'QSJDJ',
        QSJZJ: 'QSJZJ',
        CRDJ: 'CRDJ',
        CRZJ: 'CRZJ',
        LX: 'LX',
      },
      savebuttonshow: true,
      options5: [],
      showMapBtn:false,
      dialogFileVisible:false,
      mapUrl:"",
    };
  },
  methods: {
    toMap(){
      //点击跳转
      let guid=this.form.GD_GUID
       let me = this;
      this.$ajax.get(this.$appConfig.apiUrls.ywxtApi
        + '/api/YW/Getyzturlguidtype?bjid='
        + this.$route.query.instanceId + '&fid='
        + this.$route.query.fid)
        .then((r) => {
          let res = r.data.Data
          me.dialogFileVisible=true
          if (r.data.IsSuccess) {
            me.mapUrl =me.$appConfig.mapUrl2+ '?fid=' + me.$route.query.fid + '&bjid=' + me.$route.query.instanceId + '&guid=' + guid + '&type=' + res.type + '&p=1';
          } else {
            me.mapUrl =me.$appConfig.mapUrl2+ '?fid=' + me.$route.query.fid + '&bjid=' + me.$route.query.instanceId + '&guid=' + guid + '&type=' + 'GD' + '&p=1';
          }
        })
    },
    switch() {
      let me = this;
      me.form.BJID = this.$route.query.instanceId;
      if (me.form.BJID === undefined) {
        me.form.BJID = "";
      } else {
        this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/getjsxmgd?BJID=" + me.form.BJID)
          .then(function (response) {
            if (response.data.IsSuccess) {
              if (response.data.Data.GD_GUID !== null) {
                me.form = response.data.Data;
                me.showMapBtn=true;
              }
            } else {
              ElMessage({
                type: "info",
                message: "初始化表单失败",
              });
            }
          });
      }
    },
    async onSubmit() {
      let me = this
      // if (me.checkform()) {

      // }
      await this.$ajax
        .post(me.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatjsxmgd", me.form)
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.form.GD_GUID = response.data.Data;
            ElMessage({
              type: "success",
              message: "保存成功",
            });
            me.showMapBtn=true
          } else {
            ElMessage({
              type: "info",
              message: "保存失败!",
            });
            console.log(response.data.errorMessage);
          }
        });
    },
    checkform() {
      let data = [];
      let labelList = [];
      for (let k in this.forms) {
        data.push(k);
        labelList.push(this.forms[k])
      }
      let r = -1;
      let checkNum = /^([0-9][0-9]*)+(\.[0-9]{1,2})?$/
      for (let i = 0; i < data.length; i++) {
        let key = data[i]
        if (this.form[key] === '' || this.form[key] === null || this.form[key] === undefined) {
          r = i;
          break;
        } else if (i === 11 || i === 24 || i === 25 || i === 27 || i === 28) {
          let sk = data[i]
          if (!checkNum.test(this.form[sk])) {
            ElMessage({
              type: 'info',
              message: this.forms[sk] + '内容请输入数字',
            })
            return false;
          }
        }
      }
      if (r == -1) {
        return true
      } else {
        ElMessage({
          type: 'info',
          message: '请输入' + labelList[r],
        })
        return false;
      }
    },
    async getxzqh() {
      let me = this;
      await this.$ajax
        .get(me.$appConfig.apiUrls.ywxtApi + "/api/Dictionary/GetDictionary?code_class=3")
        .then(function (response) {
          if (response.data.IsSuccess) {
            response.data.Data.forEach(function (value) {
              me.options5.push({ value: value.code, label: value.code_value });
            });
          } else {
            console.log(response.data.errorMessage);
          }
        });
    },
  },
  mounted() {
    this.getxzqh()
    this.switch()
  }
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
.el-input-number {
  width: 100%;
}
</style>
